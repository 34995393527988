<template>
  <div>
    <section class="banner"></section>
    <section class="container content">
      <div class="row">
        <div class="col-12">
          <h1>{{ $t("howGlovelerWorks.howGlovelerWorks") }}</h1>
          <h5>
            {{ $t("howGlovelerWorks.title") }}
          </h5>
          <p>
            {{ $t("howGlovelerWorks.description") }}
          </p>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-7 my-md-auto my-5">
          <span class="btn-main py-1 px-2">{{
            $t("howGlovelerWorks.step1")
          }}</span>
          <h5 class="mt-2">{{ $t("howGlovelerWorks.step1Title") }}</h5>
          <p>
            {{ $t("howGlovelerWorks.step1Description") }}
          </p>
          <b
            ><p class="text-main">
              {{ $t("howGlovelerWorks.dontHaveProfile") }}
              <a href="#" class="text-main">{{
                $t("howGlovelerWorks.signUpNow")
              }}</a>
            </p></b
          >
          <button class="btn btn-main">
            {{ $t("howGlovelerWorks.signUpNow") }}
          </button>
        </div>
        <div class="col-md-5 my-md-auto my-5">
          <img
            class="img-fluid"
            src="../assets/images/profile.jpg"
            alt="Profile"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-5 my-md-auto my-5 order-md-1 order-2">
          <img
            class="img-fluid"
            src="../assets/images/explore.jpg"
            alt="Profile"
          />
        </div>
        <div class="col-md-7 my-md-auto my-5 order-md-2 order-1">
          <span class="btn-main py-1 px-2">{{
            $t("howGlovelerWorks.step2")
          }}</span>
          <h5 class="mt-2">{{ $t("howGlovelerWorks.step2Title") }}</h5>
          <p>
            {{ $t("howGlovelerWorks.step2Description") }}
          </p>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-7 my-md-auto my-5 order-md-1 order-1">
          <span class="btn-main py-1 px-2">{{
            $t("howGlovelerWorks.step3")
          }}</span>
          <h5 class="mt-2">{{ $t("howGlovelerWorks.step3Title") }}</h5>
          <p>
            {{ $t("howGlovelerWorks.step3Description") }}
          </p>
        </div>
        <div class="col-md-5 my-md-auto my-5 order-md-2 order-2">
          <img
            class="img-fluid"
            src="../assets/images/surf.jpg"
            alt="Profile"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-5 my-md-auto my-5 order-md-1 order-2">
          <img
            class="img-fluid"
            src="../assets/images/profile.jpg"
            alt="Profile"
          />
        </div>
        <div class="col-md-7 my-md-auto my-5 order-md-2 order-1">
          <span class="btn-main py-1 px-2">{{
            $t("howGlovelerWorks.step4")
          }}</span>
          <h5 class="mt-2">{{ $t("howGlovelerWorks.step4Title") }}</h5>
          <p>
            {{ $t("howGlovelerWorks.step4Description") }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HowGlovelerWorks",
  components: {},
};
</script>

<style scoped>
.banner {
  height: 70vh;
  position: relative;
  background-image: url(../assets/images/how-gloveler-works.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.banner:before {
  background-color: rgba(255, 118, 43, 0.8);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.content {
  margin-top: 100px;
  margin-bottom: 100px;
}
.content h1 {
  text-transform: uppercase;
  font-weight: bolder;
  color: #ff762b;
}
.mt-10 {
  margin-top: 90px;
}
@media only screen and (max-width: 500px) {
  .banner {
    height: 30vh;
  }

  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mt-10 {
    margin-top: 35px;
  }
}
</style>
